import clsx from 'clsx'
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'

import { ConditionalRender, TableColumn } from '@/common/components'
import { NothingFound } from '@/packages/ui'
import {
  TableContextData,
  TableContextProvider
} from '@/packages/ui/table/context'
import searchFunc from '@/packages/ui/table/helpers/search'
import {
  TableBody,
  TableHeader,
  TableTopContent
} from '@/packages/ui/table/index'
import { RowsData } from '@/packages/ui/table/types'

import styles from './Table.module.scss'

export interface TableTab {
  accessor: string
  title: string
  counter?: number
  filterFunc?: (data: RowsData) => RowsData
}

// interface Filter {
//   [key: string]: string[]
// }

type FunctionalColumns = (activeTab: string | undefined) => TableColumn[]

interface TableProps extends Omit<TableContextData, 'columns'> {
  searchFields?: string[]
  withRowClick?: boolean
  customActions?: JSX.Element
  tabs?: TableTab[]
  title?: string | JSX.Element
  columns: TableColumn[] | FunctionalColumns
  noResults?: { afterSearch: JSX.Element; byDefault: JSX.Element }
}

const Table: FC<TableProps> = (props) => {
  const {
    title,
    withRowClick,
    columns,
    data,
    tabs,
    searchFields,
    customActions,
    noResults = {
      byDefault: <NothingFound />,
      afterSearch: (
        <NothingFound isSearch text="Nothing was found for this query" />
      )
    },
    handleAction,
    highlightNewItem
  } = props

  const [activeTab, setActiveTab] = useState<number | undefined>(0)
  const [activeTabId, setActiveTabId] = useState<string | undefined>(
    tabs?.[0]?.accessor
  )
  const [search, setSearch] = useState<string | undefined>('')
  // const [filters, setFilters] = useState<Filter>({})

  const _columns = useMemo(
    () => (typeof columns === 'function' ? columns(activeTabId) : columns),
    [columns, activeTabId]
  )

  const rows = useMemo(() => {
    if (!tabs) return searchFunc(searchFields, search, data)

    return searchFunc(
      searchFields,
      search,
      tabs?.[activeTab!]?.filterFunc?.(data!)
    )
  }, [data, tabs, activeTab, search])

  const noResultsAfterSearch = search && !rows?.length

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const onTabChange = useCallback(
    (id: string) => {
      const index = tabs?.findIndex((tab) => tab.accessor === id)

      setActiveTab(index)
      setActiveTabId(id)
    },
    [tabs]
  )

  return (
    <TableContextProvider
      columns={_columns}
      data={rows}
      handleAction={handleAction}
      highlightNewItem={highlightNewItem}
    >
      <TableTopContent
        {...{
          tabs,
          title,
          search,
          onTabChange,
          searchFields,
          customActions,
          onSearchChange
        }}
      />
      <ConditionalRender
        condition={!!rows?.length}
        fallbackElement={
          noResultsAfterSearch
            ? noResults.afterSearch
            : React.cloneElement(noResults.byDefault, {
                ...noResults.byDefault.props,
                activeTab: activeTabId
              })
        }
      >
        <table className={clsx(styles.table, withRowClick && styles.clickable)}>
          <TableHeader />
          <TableBody />
        </table>
      </ConditionalRender>
    </TableContextProvider>
  )
}

export default Table
