import {FC, PropsWithChildren,ReactNode } from 'react'

import styles from './Tab.module.scss'

export type TabProps = {
  id: string
  title?: string | ReactNode
  icon?: string
  counter?: number
}

const Tab: FC<PropsWithChildren<TabProps | undefined>> = ({ children }) => (
  <div className={styles.tab}>{children}</div>
)

export default Tab
