import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { NumberFormatValues } from 'react-number-format'
import { useOnClickOutside } from 'usehooks-ts'

import { LottieConfetti } from '@/assets/lottie'
import { ConditionalRender } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import { NoPermissionsTooltip } from '@/features/dashboard'
import congratsIcon from '@/features/dashboard/assets/images/summary/congrats.png'
import goalIcon from '@/features/dashboard/assets/images/summary/goal.png'
import goalFilledIcon from '@/features/dashboard/assets/images/summary/goal-filled.png'
import { useModalContext } from '@/features/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  NumberInput,
  Row,
  Text,
  TextAlign,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'

import styles from './DashboardSummaryGoal.module.scss'

interface DashboardSummaryGoalProps {
  savedGoal: number | null
  safesAmount: number
  pendingAmount: number
  role: ProfileTypes
  companyId?: string
  permission: TeamMemberPermission
  updateGoal: (goal: number) => void
  pendingCount: number
  paidAmount: number
  openModal: () => void
  columnMode?: boolean
}

const DashboardSummaryGoal: FC<DashboardSummaryGoalProps> = (props) => {
  const {
    permission,
    updateGoal,
    companyId,
    savedGoal,
    safesAmount,
    pendingAmount,
    paidAmount,
    pendingCount,
    role,
    columnMode,
    openModal
  } = props
  const ref = useRef(null)

  const { dontHavePermissionModal } = useModalContext()

  const isEntrepreneur = role === ProfileTypes.ENTREPRENEUR

  const allowEdit = TeamMemberPermission.CREATE === permission

  const [isSetMode, setSetMode] = useState(false)
  const [tempValue, setTempValue] = useState<number>(savedGoal || 0)

  const completed = isEntrepreneur && safesAmount >= tempValue
  const showProgressBar = isEntrepreneur ? tempValue : true

  useEffect(() => {
    setTempValue(savedGoal || 0)
  }, [companyId])

  const { paid, pending } = useMemo(() => {
    const percentages = { paid: 0, pending: 0 }

    if (safesAmount === 0) {
      return percentages
    }

    if (!isEntrepreneur && !!safesAmount) {
      percentages.paid = +((paidAmount / safesAmount) * 100).toFixed(2)
      percentages.pending = +(100 - percentages.paid).toFixed(2)

      return percentages
    }

    if (paidAmount >= tempValue) {
      percentages.paid = 100
    } else if (!!paidAmount) {
      percentages.paid = +((paidAmount / tempValue) * 100).toFixed(2)
    }

    if (pendingAmount >= tempValue) {
      percentages.pending = 100
    } else if (!!pendingAmount) {
      percentages.pending = +((pendingAmount / tempValue) * 100).toFixed(2)
    }

    if (percentages.paid + percentages.pending > 100) {
      percentages.pending = +(100 - percentages.paid).toFixed(2)
    }

    return percentages
  }, [tempValue, safesAmount, pendingAmount])

  const openSetMode = useCallback(() => {
    if (!allowEdit) {
      dontHavePermissionModal.openModal()
      return
    }

    setSetMode(true)
  }, [allowEdit])

  const saveGoal = () => {
    setSetMode(false)
    updateGoal(tempValue || 0)
  }

  const cancel = () => {
    setSetMode(false)
    setTempValue(savedGoal || 0)
  }

  useOnClickOutside(ref, () => {
    if (isSetMode) {
      cancel()
    }
  })

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveGoal()
    }

    if (event.key === 'Escape') {
      cancel()
    }
  }

  const ItemComponent =
    role === ProfileTypes.ENTREPRENEUR || columnMode ? Col : Row

  const NoGoalScreen = (
    <Col gap={6} items="center" justify="center" className={styles.goalBlock}>
      <img src={goalIcon} height={42} alt="goal" />

      <Text
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.SEMIBOLD}
        color={Color.neutral500}
        align={TextAlign.CENTER}
      >
        {isEntrepreneur
          ? 'Set a goal amount'
          : 'You don’t have any investments yet.'}
      </Text>

      <ConditionalRender
        condition={isEntrepreneur}
        fallbackElement={
          <div className={styles.tooltip}>
            <Tooltip kind={TooltipKind.INFO} content="Lorem ipsum" />
          </div>
        }
      >
        <NoPermissionsTooltip permission={permission}>
          <Button
            width="fit"
            appearance="ordinary"
            onClick={openSetMode}
            className="!tw-px-[20px] !tw-pt-[10px]"
          >
            <SharedIcons.PlusThin size={23} className="tw-mr-2" />
            Set goal
          </Button>
        </NoPermissionsTooltip>
      </ConditionalRender>
    </Col>
  )

  const WithGoalScreen = (
    <Col
      items="stretch"
      justify="center"
      className={clsx(
        styles.goalBlock,
        styles.withoutStars,
        !isEntrepreneur && styles.angelGoal,
        completed && styles.completed
      )}
    >
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />
      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <Row
        items={isEntrepreneur ? 'center' : 'start'}
        justify="between"
        gap={20}
        className="tw-mb-[13px]"
      >
        <Row
          items="center"
          reverse={!isEntrepreneur}
          justify={isEntrepreneur ? 'start' : 'between'}
          className={isEntrepreneur ? '' : 'tw-w-full'}
          gap={8}
        >
          <img
            src={completed ? congratsIcon : goalFilledIcon}
            width={32}
            alt="Goal"
          />

          <ConditionalRender
            condition={isEntrepreneur}
            fallbackElement={
              <Row items="end" gap={5}>
                <Heading
                  color={Color.primary500}
                  weight={FontWeight.SEMIBOLD}
                  type={HeadingTypes.H3}
                >
                  {toCurrencyFormat(safesAmount, '$')}
                </Heading>

                <Text color={Color.neutral300} type={TextTypes.BODY_SMALL}>
                  invested
                </Text>
              </Row>
            }
          >
            <Text
              weight={FontWeight.SEMIBOLD}
              type={TextTypes.BODY_DEFAULT}
              className="!tw-leading-snug"
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {completed ? 'Goal accomplished!' : 'Goal progress'}
            </Text>
          </ConditionalRender>
        </Row>

        <ConditionalRender condition={role === ProfileTypes.ENTREPRENEUR}>
          <NoPermissionsTooltip permission={permission}>
            <Row
              items="center"
              gap={3}
              className="tw-cursor-pointer"
              onClick={openSetMode}
            >
              <ConditionalRender condition={!completed}>
                <SharedIcons.Pencil size={15} color={Color.primary500} />
              </ConditionalRender>
              <Text
                type={TextTypes.BODY_SMALL}
                weight={FontWeight.SEMIBOLD}
                color={Color.primary500}
                style={{ zIndex: 100 }}
              >
                {completed ? 'Raise goal' : 'Edit'}
              </Text>
            </Row>
          </NoPermissionsTooltip>
        </ConditionalRender>
      </Row>

      <ConditionalRender condition={isEntrepreneur}>
        <Row items="end" justify="between" gap={20}>
          <Row items="end" gap={5}>
            <Text
              color={Color.neutral500}
              weight={FontWeight.MEDIUM}
              type={TextTypes.BODY_DEFAULT}
            >
              {toCurrencyFormat(paidAmount, '$')} out of{' '}
              {toCurrencyFormat(safesAmount, '$')}
            </Text>

            <Text color={Color.neutral300} type={TextTypes.BODY_SMALL}>
              raised
            </Text>
          </Row>

          <Row items="center" gap={5}>
            <Heading
              color={Color.neutral500}
              weight={FontWeight.BOLD}
              type={HeadingTypes.H2}
            >
              {toCurrencyFormat(tempValue || 0, '$')}
            </Heading>

            <Text color={Color.neutral300} type={TextTypes.BODY_SMALL}>
              goal
            </Text>
          </Row>
        </Row>
      </ConditionalRender>

      <div className={styles.progressBg}>
        <ConditionalRender condition={!!paid}>
          <div
            className={clsx(styles.greenLine, paid === 100 && styles.rounded)}
            style={{ width: `${paid}%` }}
          />
        </ConditionalRender>
        <ConditionalRender condition={!!pending}>
          <Tooltip
            placement="bottom"
            content={
              <Col>
                <Row items="center" gap={5}>
                  <Text
                    type={TextTypes.BODY_DEFAULT}
                    color={Color.warning300}
                    weight={FontWeight.SEMIBOLD}
                  >
                    {toCurrencyFormat(pendingAmount, '$')}
                  </Text>
                  <Text type={TextTypes.BODY_SMALL} color={Color.neutral300}>
                    Pending
                  </Text>
                </Row>
                <Text color={Color.neutral300} type={TextTypes.BODY_SMALL}>
                  from{' '}
                  <span className={styles.safesNumber}>{pendingCount}</span>{' '}
                  SAFEs
                </Text>
              </Col>
            }
          >
            <div
              className={clsx(styles.yellowLine, !paid && styles.rounded)}
              style={{ left: `${paid}%`, width: `${pending}%` }}
            />
          </Tooltip>
        </ConditionalRender>
      </div>

      <div
        className={clsx(
          styles.stats,
          !isEntrepreneur && styles.onlyTwoSections
        )}
      >
        <ItemComponent
          className={styles.statsItem}
          justify={isEntrepreneur ? 'start' : 'between'}
          gap={1}
        >
          <Row items="center" gap={5}>
            <span className={clsx(styles.circle, styles.yellow)} />
            <Text color={Color.neutral400} type={TextTypes.BODY_SMALL}>
              {isEntrepreneur ? 'Unpaid SAFEs' : 'SAFEs Unpaid'}
            </Text>
            <ConditionalRender condition={!!pendingCount && isEntrepreneur}>
              <div onClick={openModal} className={styles.pendingSAFEsCount}>
                {pendingCount}
              </div>
            </ConditionalRender>
          </Row>

          <Text
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
            type={TextTypes.BODY_SMALL}
          >
            {toCurrencyFormat(pendingAmount, '$')}
          </Text>
        </ItemComponent>

        <ItemComponent
          className={styles.statsItem}
          gap={1}
          justify={isEntrepreneur ? 'start' : 'between'}
        >
          <Row items="center" gap={5}>
            <span className={clsx(styles.circle, styles.green)} />
            <Text color={Color.neutral400} type={TextTypes.BODY_SMALL}>
              SAFEs Paid
            </Text>
          </Row>

          <Text
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
            type={TextTypes.BODY_SMALL}
          >
            {toCurrencyFormat(paidAmount, '$')}
          </Text>
        </ItemComponent>

        <ConditionalRender condition={isEntrepreneur}>
          <Col className={styles.statsItem} gap={1}>
            <Text color={Color.neutral400} type={TextTypes.BODY_SMALL}>
              Remaining goal
            </Text>

            <Text
              weight={FontWeight.SEMIBOLD}
              color={Color.neutral500}
              type={TextTypes.BODY_SMALL}
            >
              {toCurrencyFormat(
                Math.max(0, (tempValue || 0) - paidAmount),
                '$'
              )}
            </Text>
          </Col>
        </ConditionalRender>
      </div>
    </Col>
  )

  return (
    <div ref={ref}>
      <ConditionalRender
        condition={isSetMode}
        fallbackElement={showProgressBar ? WithGoalScreen : NoGoalScreen}
      >
        <Col
          gap={7}
          items="center"
          justify="center"
          className={styles.goalBlock}
        >
          <Text
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
            align={TextAlign.CENTER}
            className="!tw-leading-snug"
          >
            Set your goal amount
          </Text>

          <Text
            type={TextTypes.BODY_SMALL}
            color={Color.neutral500}
            align={TextAlign.CENTER}
            className="!tw-leading-snug"
          >
            We’ll help keep track of the money you{' '}
            {isEntrepreneur ? 'raise' : 'invest'} money.
          </Text>

          <div className={styles.goalInput}>
            <NumberInput
              autoFocus
              placeholder={toCurrencyFormat(1000000, '$')}
              prefix="$"
              onKeyDown={handleKeyDown}
              value={!!tempValue ? tempValue : undefined}
              onValueChange={(values: NumberFormatValues) =>
                setTempValue(values.floatValue!)
              }
            />
          </div>

          <Row items="center" gap={12}>
            <Button
              uppercase
              width="fit"
              appearance="secondary"
              onClick={cancel}
              className={styles.goalActionBtn}
            >
              Cancel
            </Button>

            <Button
              uppercase
              width="fit"
              appearance="primary"
              onClick={saveGoal}
              disabled={!tempValue}
              className={styles.goalActionBtn}
            >
              Save goal
            </Button>
          </Row>
        </Col>
      </ConditionalRender>
    </div>
  )
}

export default DashboardSummaryGoal
