import clsx from 'clsx'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'

import { ConditionalRender } from '@/common/components'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Spacer } from '@/packages/ui'
import { TabProps } from '@/packages/ui/tabs/Tab/Tab'

import { Text, TextTypes } from '../typography'
import styles from './Tabs.module.scss'

type IKind =
  | 'compact'
  | 'modern'
  | 'module'
  | 'three_d'
  | 'simple'
  | 'switchKind'

interface TabsProps {
  initialTabId?: string
  onTabChange?: (key: string, index: number) => void
  children?: ReactNode
  kind?: IKind
  withoutContent?: boolean
  className?: string
}

const Tabs: FC<TabsProps> = ({
  initialTabId,
  onTabChange,
  children,
  kind = 'compact',
  withoutContent,
  className
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState<string>()
  const tabs: any = React.Children.toArray(children)
  const threeDMode = kind === 'three_d'

  useEffect(() => {
    const tabId = initialTabId || (tabs[0].props as TabProps).id
    setActiveTab(tabId)
    onTabChange?.(tabId, 0)
  }, [initialTabId])

  const selectTab = useCallback(
    (id: string, index: number) => () => {
      setActiveTab(id)
      onTabChange?.(id, index)
    },
    []
  )

  const getActiveTabIndex = useCallback(() => {
    const index = tabs.findIndex(
      (p: ReactElement) => (p.props as TabProps).id === activeTab
    )
    return index === -1 ? 0 : index
  }, [activeTab])

  const renderTabsHeader = () =>
    tabs.map((tab: ReactElement, index: number) => {
      const { title, id, icon, counter } = tab.props as TabProps
      const active = activeTab === id

      return (
        <li
          key={id}
          onClick={selectTab(id, index)}
          className={clsx(styles.item, active && styles.active)}
        >
          <ConditionalRender condition={threeDMode}>
            <div className={styles.iconWrapper}>
              <img src={icon} alt={`${id}-icon`} />
            </div>
          </ConditionalRender>
          <Text className={styles.text} type={TextTypes.BODY_DEFAULT}>
            {title}
            <ConditionalRender condition={counter !== undefined}>
              {' '}({counter})
            </ConditionalRender>
          </Text>

          <ConditionalRender condition={threeDMode}>
            <ConditionalRender
              condition={active}
              fallbackElement={<span className={styles.inactiveTab} />}
            >
              <Spacer size={4} vertical />
              <SharedIcons.Checkmark color={Color.primary400} size={14} />
            </ConditionalRender>
          </ConditionalRender>
        </li>
      )
    })

  return (
    <div className={clsx(styles.tabs, styles[kind], className)}>
      <ul className={styles.header}>{renderTabsHeader()}</ul>
      <ConditionalRender condition={!withoutContent}>
        <div className={styles.tabContent}>{tabs[getActiveTabIndex()]}</div>
      </ConditionalRender>
    </div>
  )
}

export default Tabs
