import React, { ChangeEvent, FC } from 'react'

import { ConditionalRender, Search } from '@/common/components'
import { SAFE_NOTES_TABS } from '@/features/dashboard/enums'
import { Color } from '@/packages/palette'
import { Heading, HeadingTypes, Row, Tab, Tabs } from '@/packages/ui'
import { TableTab } from '@/packages/ui/table/Table'

import styles from './TableTopContent.module.scss'

interface TableTopContentProps {
  title?: string | JSX.Element
  search: string | undefined
  onTabChange: (id: string) => void
  searchFields?: string[]
  customActions?: JSX.Element
  tabs?: TableTab[]
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const TableTopContent: FC<TableTopContentProps> = (props) => {
  const {
    title,
    tabs,
    search,
    onTabChange,
    searchFields,
    customActions,
    onSearchChange
  } = props

  const withTabs = !!tabs?.length

  if (!searchFields?.length && !customActions && !withTabs) return null

  return (
    <>
      {!!title && (
        <Row items="stretch" justify="between">
          <ConditionalRender
            condition={typeof title === 'string'}
            fallbackElement={title}
          >
            <Heading type={HeadingTypes.H2} color={Color.neutral500}>
              {title}
            </Heading>
          </ConditionalRender>

          <ConditionalRender
            condition={!!searchFields?.length}
            fallbackElement={customActions}
          >
            <Row gap={12} items="stretch" justify="end" className={styles.row}>
              <Search search={search} onSearchChange={onSearchChange} />
              {customActions}
            </Row>
          </ConditionalRender>
        </Row>
      )}
      <Row items="center" justify={withTabs ? 'between' : 'end'}>
        <ConditionalRender condition={withTabs}>
          <Tabs
            initialTabId={SAFE_NOTES_TABS.ALL}
            kind="modern"
            onTabChange={onTabChange}
          >
            {tabs?.map((tab) => (
              <Tab id={tab.accessor} key={tab.accessor} title={tab.title} counter={tab.counter}  />
            ))}
          </Tabs>
        </ConditionalRender>
      </Row>
    </>
  )
}

export default TableTopContent
