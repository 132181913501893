import clsx from 'clsx'
import { CSSProperties, FC } from 'react'

import { SharedImages } from '@/assets/images'
import { ConditionalRender } from '@/common/components'
import { getInitialsFromName } from '@/common/utils'
import { Color } from '@/packages/palette'
import { FontWeight, Text, TextTypes } from '@/packages/ui'

import styles from './Avatar.module.scss'

interface AvatarProps {
  name?: string
  image?: string | null
  defaultImage?: string | null
  size?: number
  className?: string
  textSize?: TextTypes
}

const Avatar: FC<AvatarProps> = ({
  name,
  image,
  size = 40,
  className,
  textSize = TextTypes.BODY_EXTRA_SMALL,
  defaultImage
}: AvatarProps) => {
  const PrimaryView = (
    <div
      className={clsx(className, styles.container)}
      style={{ '--size': `${size}px` } as CSSProperties}
    >
      <img src={image || defaultImage || ''} alt="user-avatar" />
    </div>
  )

  const SecondaryView = (
    <ConditionalRender
      condition={!!name}
      fallbackElement={
        <div
          className={clsx(
            className,
            'tw-rounded-[50%] tw-flex tw-items-center tw-justify-center'
          )}
        >
          <img
            style={{ width: size, height: size } as CSSProperties}
            src={SharedImages.UserPlaceholder}
            alt="user-avatar-placeholder"
          />
        </div>
      }
    >
      <div
        className={clsx(className, styles.container, !!name && styles.withBg)}
        style={{ '--size': `${size}px` } as CSSProperties}
      >
        <Text
          type={textSize}
          weight={FontWeight.BOLD}
          color={Color.neutral0}
        >
          {getInitialsFromName(name)}
        </Text>
      </div>
    </ConditionalRender>
  )

  return !!image || !!defaultImage ? PrimaryView : SecondaryView
}

export default Avatar
